import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import LayoutExtra from "../components/layoutExtra"

import CustomForm from '../components/customForm'

const RecruitApplication = ({ location }) => {

  const data = useStaticQuery(graphql`
    query {
			applicationIcon: file(relativePath: { eq: "icons/application.svg" }) {
        publicURL
      },
    }
	`)

	// フォーム入力項目
	const formSettings = {
		name: {
			id: "name",
			label: "お名前",
			type: "text-group-flex",
			required: true,
			requiredColumn: "all",
			groups: [
				{
					id: "family",
					type: "text",
					label: "姓",
					length: "20",
					placeholder: "例）⼭⽥",
					valid: "string",
					width: "45%",
				},
				{
					id: "first",
					type: "text",
					label: "名",
					length: "20",
					placeholder: "例）花⼦",
					valid: "string",
					width: "45%",
				}
			]
		},
		furigana: {
			id: "furigana",
			label: "フリガナ",
			type: "text-group-flex",
			required: true,
			requiredColumn: "all",
			groups: [
				{
					id: "family",
					type: "text",
					label: "姓",
					length: "20",
					placeholder: "例）ヤマダ",
					valid: "string",
					width: "45%",
				},
				{
					id: "first",
					type: "text",
					label: "名",
					length: "20",
					placeholder: "例）ハナコ",
					valid: "string",
					width: "45%",
				}
			]
		},
		birthday: {
			id: "birthday",
			label: "⽣年⽉⽇",
			type: "text-group-flex",
			required: true,
			requiredColumn: "all",
			groups: [
				{
					id: "year",
					type: "tel",
					label: "西暦",
					labelappend: "年",
					length: "4",
					valid: "number",
					width: "45%",
					
				},
				{
					id: "month",
					type: "tel",
					labelappend: "月",
					length: "2",
					valid: "number",
					width: "25%",
				},
				{
					id: "date",
					type: "tel",
					labelappend: "日",
					length: "2",
					valid: "number",
					width: "25%",
				}
			]
		},
		sex: {
			id: "sex",
			label: "性別",
			type: "radio",
			required: true,
			options: [
				{ id: "male", 	label: "男性" },
				{ id: "female", label: "女性" },
			],
			valid: null
		},
		zipcode: {
			id: "zipcode",
			label: "郵便番号",
			type: "tel",
			length: "8",
			placeholder: "例）450-0003",
			valid: "zipcode",
			required: true,
			width: "50%"
		},
		prefecture: {
			id: "prefecture",
			label: "都道府県",
			type: "text",
			length: "10",
			placeholder: "例）愛知県",
			required: true,
			valid: "string",
			width: "50%"
		},
		city: {
			id: "city",
			label: "市区町村・町名",
			type: "text",
			length: "30",
			placeholder: "例）名古屋市中村区名駅南",
			required: true,
			valid: "string",
		},
		addressline: {
			id: "addressline",
			label: "番地・号・建物名など",
			type: "text",
			length: "30",
			placeholder: "例）2-14-19　住友⽣命名古屋ビル21階",
			required: true,
			valid: "string",
		},
		email: {
			id: "email",
			label: "メールアドレス",
			type: "email",
			length: "50",
			placeholder: "例）abcdefg@g-wise.co.jp",
			required: true,
			valid: "email"
		},
		tel: {
			id: "tel",
			label: "電話番号",
			type: "tel",
			length: "13",
			placeholder: "例）0525812600（半角数字のみ）",
			required: false,
			valid: "tel",
		},
		working: {
			id: "working",
			label: "就業状況",
			type: "radio",
			required: true,
			options: [
				{ id: "working", 	label: "就業中" },
				{ id: "not-working", label: "就業していない" },
			],
			valid: null
		},
		experience: {
			id: "experience",
			label: "経験職務",
			type: "text-group-vertical",
			text: "ご経験のある職務の経験年数をご記入ください。",
			required: true,
			requiredColumn: "all",
			groups: [
				{
					id: 1,
					type: "tel",
					labelprepend: "アプリケーション開発（オープン・Web）",
					labelappend: "年",
					length: "2",
					valid: "number",
					default : "0"
				},
				{
					id: 2,
					type: "tel",
					labelprepend: "アプリケーション開発（汎⽤）",
					labelappend: "年",
					length: "2",
					valid: "number",
					default : "0"
				},
				{
					id: 3,
					type: "tel",
					labelprepend: "アプリケーション開発（制御・組込）",
					labelappend: "年",
					length: "2",
					valid: "number",
					default : "0"
				},
				{
					id: 4,
					type: "tel",
					labelprepend: "ネットワーク・サーバー設計・構築",
					labelappend: "年",
					length: "2",
					valid: "number",
					default : "0"
				},
				{
					id: 5,
					type: "tel",
					labelprepend: "ネットワーク・サーバ運⽤・保守",
					labelappend: "年",
					length: "2",
					valid: "number",
					default : "0"
				},
				{
					id: 6,
					type: "tel",
					labelprepend: "社内SE",
					labelappend: "年",
					length: "2",
					valid: "number",
					default : "0"
				},
				{
					id: 7,
					type: "tel",
					labelprepend: "その他",
					labelappend: "年",
					length: "2",
					valid: "number",
					default : "0"
				},
			]
		},
		details: {
			id: "details",
			label: "主な業務内容",
			type: "textarea",
			text: "500字以内でご記入ください。",
			length: "500",
			required: false,
			valid: null
		},
		questions: {
			id: "questions",
			label: "質問・備考など",
			type: "textarea",
			text: "500字以内でご記入ください。",
			length: "500",
			required: false,
			valid: null
		}
	}

	// 修正時に確認画面から渡される値
	let values = null
	if( location.state ) values = location.state.values

	return (
		<LayoutExtra>
			<SEO title="採⽤エントリー" description="採用エントリーはこちらのページよりお送りください。"/>
			<div className="page-head">
				<div className="h2-subtitle">
					<img src={data.applicationIcon.publicURL} alt="お問い合わせ"/>
					<h2>採⽤エントリーフォーム</h2>
					<p>Application Form</p>
				</div>
				<p>下記フォームに必要事項をご⼊⼒の上、ご応募ください。</p>
			</div>
			<CustomForm 
				values={values}
				formSettings={formSettings} 
				pathname={location.pathname}
				submitPath="/recruitSubmit/"
			/>
		</LayoutExtra>
	)
}

export default RecruitApplication